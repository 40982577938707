import * as React from 'react';
import { Link } from 'gatsby';
import { Segment, Container, List, Image, Divider } from 'semantic-ui-react';

import { colors } from './sidebar/utils/tokens';

import logoImage from '../images/logos/mondaic_logo_tiny_no_text.png';

export class Footer extends React.Component {
  public render() {
    return (
      <div css={{ flexShrink: '0' }}>
        <Segment vertical style={{ margin: '2em 0em 0em', padding: '0em 0em' }}>
          <Container
            textAlign="center"
            style={{
              paddingTop: `2em`,
            }}
          >
            <Divider section />
            <Image centered size="mini" src={logoImage} />
            <div>
              <List horizontal divided link size="small">
                <List.Item>&#xA9; Mondaic AG (2024)</List.Item>
              </List>
            </div>

            <List horizontal divided link size="small">
              <List.Item as="a" href="/sitemap/sitemap-index.xml">
                Site Map
              </List.Item>
              <List.Item as="a" href="mailto:info@mondaic.com?subject=Inquiry">
                Contact Us
              </List.Item>
              <List.Item>
                <Link to="/impressum">Impressum</Link>
              </List.Item>
              <List.Item>
                <Link to="/privacy_policy">Privacy Policy</Link>
              </List.Item>
              <List.Item>
                <a href="/ncla.pdf">Academic License Agreement</a>
                <a href="/pncla.pdf"> </a>
              </List.Item>
              <List.Item>
                <Link to="/credits">Credits</Link>
              </List.Item>
            </List>
          </Container>
        </Segment>
      </div>
    );
  }
}
